import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useHover from '@assets/scripts/hooks/useHover';

// data
import i18n from '@data/i18n';
import { home as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/LinkPage';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
import Cta from '@components/molecules/Cta';
import ItemArticle from '@components/molecules/items/ItemArticle';
import Intro from '@components/organisms/Intro';
import ListClients from '@components/organisms/ListClients';
import ListTeam from '@components/organisms/ListTeam';
import ListProjects from '@components/organisms/ListProjects';
import gifFwt from '@assets/images/projects/thumbs/SQUARE-FWT.gif';

// --------------------------------
// #region data
// --------------------------------

const pageClass = 'page-homepage';

const language = 'fr';

const seo = {
	title: 'Superhuit – Agence digitale Suisse à Lausanne et Genève',
	description:
		"Agence de communication digitale et de solutions web centrée sur l'impact des marques. Conseils, stratégies, sites Internet, e-commerce ou application et création de marque.",
};

const relatedLinks = [
	{ title: 'L’Agence', url: '/fr/agence/' },
	{ title: 'Projets', url: '/fr/projets/' },
];

const IndexPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				wpgraphql {
					posts(
						first: 3
						where: {
							language: FR
							orderby: { field: DATE, order: DESC }
							parent: null # only get root posts, no children
						}
					) {
						nodes {
							id
							databaseId
							uri
							title
							date
						}
					}
				}
				projectImages: allFile(
					filter: {
						relativePath: {
							in: [
								"projects/thumbs/PORTRAIT-ECA.jpg"
								"projects/thumbs/SQUARE-Ackermann.jpg"
								"projects/thumbs/PORTRAIT-Vidy.jpg"
							]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 632, quality: 90) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
				teamImages: allFile(
					filter: {
						relativePath: {
							in: [
								"team/angela.jpg"
								"team/luc.jpg"
								"team/felipe.jpg"
								"team/sylvain.jpg"
								"team/charlotte.jpg"
								"team/alexandre.jpg"
								"team/kilian.jpg"
								"team/simeon.jpg"
								"team/samuel.jpg"
								"team/pierrick.jpg"
								"team/emilien.jpg"
								"team/fabien.jpg"
								"team/hugonew.jpg"
								"team/steve.jpg"
								"team/julie.jpg"
								"team/nadine.jpg"
								"team/raphael.jpg"
								"team/arno.jpg"
								# "team/alix.jpg"
								"team/sullivan.jpg"
								"team/marion.jpg"
							]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 228, quality: 90) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		`
	);

	// Extract the data from the GraphQL query results
	const {
		wpgraphql: {
			posts: { nodes },
		},
	} = data;

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/fr/projets/eca-vaud/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-ECA.jpg'
			),
			title: 'ECA Vaud',
			subtitle: 'Conception UI – Design – Développement web - Workshops',
		},
		{
			link: '/fr/projets/fwt-freeride-world-tour/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifFwt,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 496 / 496, // width / height
			},
			title: 'FWT - Freeride World Tour',
			subtitle: 'Conception UI – Design – Développement web - Workshops',
			linkedProjects: [
				{
					link: '/fr/projets/fwt-freeride-world-tour-branding/',
					title: 'Découvrir le FWT rebranding',
				},
			],
		},
		{
			link: '/fr/projets/ackermann-branding/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-Ackermann.jpg'
			),
			title: 'Ackermann',
			subtitle: 'Branding – Positionnement de marque – Packaging',
			linkedProjects: [
				{
					link: '/fr/projets/ackermann-typographie/',
					title: 'Typographie Ackermann sur mesure',
				},
			],
		},
		{
			link: '/fr/projets/theatre-de-vidy-lausanne/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-Vidy.jpg'
			),
			title: 'Théâtre de Vidy-Lausanne',
			subtitle: 'Conception UX – Design – Développement web',
		},
	];

	const teamMembers = [
		{
			name: 'Emilien',
			role: 'Visual Designer',
			image: data.getImageAttributes('teamImages', 'team/emilien.jpg'),
			link: '/todo',
		},
		{
			name: 'Charlotte',
			role: 'Frontend Developer',
			image: data.getImageAttributes('teamImages', 'team/charlotte.jpg'),
			link: '/todo',
		},
		{
			name: 'Fabien',
			role: 'Founder, Strategist',
			image: data.getImageAttributes('teamImages', 'team/fabien.jpg'),
			link: '/todo',
		},
		{
			name: 'Raphaël',
			role: 'Client Partner',
			image: data.getImageAttributes('teamImages', 'team/raphael.jpg'),
			link: '/todo',
		},
		{
			name: 'Hugo',
			role: 'Partner, Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/hugonew.jpg'),
			link: '/todo',
		},
		{
			name: 'Samuel',
			role: 'Partner, Business Developer',
			image: data.getImageAttributes('teamImages', 'team/samuel.jpg'),
			link: '/todo',
		},
		{
			name: 'Felipe',
			role: 'Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/felipe.jpg'),
			link: '/todo',
		},
		{
			modifiers: 'statistic',
			name: 'talents',
			statistic: '17',
			link: '/fr/equipe/',
		},
	];

	// interaction
	const [hoverOn, hoverOff] = useHover({ dotScale: 0.8 });

	return (
		<Layout
			pageClass={pageClass}
			language={language}
			footerRelatedLinks={relatedLinks}
			location={props.location}
			translations={[{ uri: '/en/', lang: 'en' }]}
			seo={seo}
		>
			<Intro
				title={
					// <>
					// 	Des stratèges
					// 	<br />
					// 	au service de
					// 	<br />
					// 	votre&nbsp;marque
					// </>
					<>
						Établir des marques
						<br />
						interactives qui
						<br />
						dépassent le digital
					</>
					// <>
					// 	Transformez votre
					// 	<br />
					// 	activité en une
					// 	<br />
					// 	marque interactive
					// </>
				}
				subtitle="Agence digitale suisse – Web & Branding à Lausanne et Genève"
				servicesTitle="We amplify"
				services={[
					{
						text: 'Stratégie',
						link: '/fr/agence-strategie-digitale/',
						alt: 'Agence digitale suisse – Stratégie',
					},
					{
						text: 'Branding',
						link: '/fr/agence-branding-suisse/',
						alt: 'Agence Branding',
					},
					{
						text: 'Solutions web',
						link: '/fr/agence-web-suisse/',
						alt: ' Agence web suisse',
					},
				]}
			/>

			<section className="grid" aria-label="Moto">
				<div className="box text-content" data-animation-page>
					<h2 className="h2-like-uptitle">Agence digitale suisse</h2>
					<p className="p-like-h2">
						Nous créons des{' '}
						<Link
							to="/fr/agence-branding-suisse/"
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							marques
						</Link>{' '}
						et des{' '}
						<Link
							to="/fr/agence-web-suisse/"
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							solutions&nbsp;web
						</Link>{' '}
						qui tapent dans le mille.
					</p>
				</div>
			</section>

			<section aria-label="Projects">
				<SectionTitle
					title="Les projets parlent mieux"
					className="section-title--more-margin"
				/>
				<div className="grid" data-animation-page>
					<ListProjects
						projectsList={projectsList}
						link={{
							title: 'Voir d’autres projets',
							url: '/fr/projets/',
						}}
					/>
				</div>
			</section>

			<section className="grid" aria-label="Clients">
				<div
					className="box text-center text-content"
					data-animation-page
				>
					<p className="uptitle">Nous collaborons avec</p>
					<ListClients clientsList={clientsList} />
				</div>
				<Cta
					text="…et si c’était le début d’une grande aventure ensemble&nbsp;?"
					cta="Mandatez-nous"
					url="/fr/contact/"
				/>
			</section>

			<section
				className="grid-content grid text-content"
				aria-label="Équipe"
				data-animation-page
			>
				<h2>Derrière chaque projet se cachent des visages</h2>

				<ListTeam
					modifiers="zigzag"
					className={['justify-center', 'col-full']}
					teamMembers={teamMembers}
				/>

				<ButtonPrimary
					className="col-full element-center"
					href="/fr/equipe/"
					text="L'équipe"
				/>
			</section>

			{nodes && !!nodes.length && (
				<section
					className="grid-content grid text-content"
					aria-label="Articles"
					data-animation-page
				>
					<h2>Nos derniers articles</h2>
					<div className="text-content element-spacing">
						{nodes.map((post) => (
							<ItemArticle
								key={post.id}
								link={{
									title: i18n[language].readPost,
									url: post.uri,
								}}
								title={post.title}
							/>
						))}
					</div>
				</section>
			)}

			{/* <section className="grid" aria-label="Newsletter">
				<Cta
					subscribe
					text={
						<>
							Devenez un insider de&nbsp;l’agence&nbsp;?
							<br />
							Recevez notre newsletter
						</>
					}
					cta="S’inscrire"
					placeholder="Votre email"
					url="/todo"
				/>
			</section> */}
		</Layout>
	);
};

export default IndexPage;
